const validation = {
    name: {
        required: 'We need to know who are we talking to',
        minLength: {
            value: 3,
            message: 'How can you write your name wih less than 3 letters?'
        },
        maxLength: {
            value: 80,
            message: 'That is a very long name you have there, try something shorter'
        }
    },
    email: {
        required: 'Without your email we can not get in touch with you',
        pattern: {
            value: /^\S+@\S+$/i,
            message: 'That does not look like a valid email address'
        }
    },
    phone: {
        required: 'We need your phone number to get in touch with you',
        minLength: {
            value: 6,
            message: 'There seem to be some numbers missing on your phone'
        },
        maxLength: {
            value: 12,
            message: 'There seem to be too many number on your phone'
        },
        pattern: {
            value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
            message: 'That does not look like a valid phone number'
        }
    },
    skills: {
        required: 'We need to know your skill level'
    },
    ac: {
        required: 'We need to know if you have studied with us before'
    },
    purchase: {
        required: 'We need to know which course you are interested in'
    }
};

export default validation;
