import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { Title, SubTitle, Form, FormGroup, FormButton } from '../../components/form';
import { convert as googleConvert } from '../../analytics/google';
import { convert as pixelConvert } from '../../analytics/facebook';

const Step5Checkout = () => {
    useAnalyticsConvertEvents();

    return (
        <>
            <Title>Thanks!</Title>
            <SubTitle>
                We’ll contact you A$AP to let you know how the next steps will roll, deal?
            </SubTitle>
            <Form>
                <FormGroup>
                    <FormButton as={Link} to="/">
                        &lt;Go back to the Homepage&gt;
                    </FormButton>
                </FormGroup>
            </Form>
        </>
    );
};

export default Step5Checkout;

function useAnalyticsConvertEvents() {
    useEffect(() => {
        googleConvert();
        pixelConvert();
    }, []);
}
