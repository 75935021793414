import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as api from './api';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './update-action';
import validation from './validation';
import {
    Title,
    SubTitle,
    Form,
    FormGroup,
    FormLabel,
    FormFieldText,
    FormCheck,
    FormError,
    FormButton
} from '../../components/form';

const Step4Questions = ({ onNext }) => {
    const { register, handleSubmit, formState, errors } = useForm();
    const { action, state } = useStateMachine(updateAction);
    const [serverError, setServerError] = useState(null);

    const onSubmit = async data => {
        action(data);
        setServerError(null);
    };

    useEffect(() => {
        if (formState.isSubmitting) {
            doSubmit();
        }
    }, [state]);

    const doSubmit = async () => {
        try {
            await api.submit(state.formData);
            onNext();
        } catch (error) {
            setServerError(error.message);
        }
    };

    return (
        <>
            <Title>Any Questions?</Title>
            <SubTitle>Use the box below to write up any questions or doubts you may have.</SubTitle>
            <Form>
                <FormGroup>
                    <FormLabel>Your message (optional)</FormLabel>
                    <FormFieldText
                        name="observations"
                        placeholder="A.M.A. right here!"
                        defaultValue={state.formData.observations}
                        ref={register(validation.observations)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormCheck
                        name="gdpr"
                        defaultValue={state.formData.gdpr}
                        ref={register({ required: 'We need your permission to keep your data' })}
                    >
			    By clicking &lt;Next&gt; you are sharing your data with us. You have complete control over your data. If you ever want it erased, just contact us at helloworld@academiadecodigo.org!
                    </FormCheck>
                </FormGroup>
                <FormGroup>
                    <FormError>
                        {errors.gdpr && <ErrorMessage errors={errors} name="gdpr" as="p" />}
                    </FormError>
                </FormGroup>
                {serverError && (
                    <FormGroup>
                        <FormError error>
                            <p>Oooops, I seem to be unable to submit this form...</p>
                        </FormError>
                    </FormGroup>
                )}
                <FormGroup>
                    <FormButton disabled={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                        &lt;Next&gt;
                    </FormButton>
                </FormGroup>
            </Form>
        </>
    );
};

Step4Questions.propTypes = {
    onNext: PropTypes.func
};

Step4Questions.defaultProps = {
    onNext: () => {}
};

export default Step4Questions;
