import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StateMachineProvider, createStore, useStateMachine } from 'little-state-machine';
import updateAction from '../sections/apply/update-action';
import ApplyForm from '../sections/apply';

import Layout from '../layout';

const log = store => console.log(store);

createStore(
    {
        formData: {}
    },
    { middleWares: [log] }
);

const ApplyPage = ({ location }) => {
    const { action } = useStateMachine(updateAction);

    useEffect(() => {
        if (!location.state) {
            return;
        }

        action({ purchase: location.state.purchase });
    }, [location.state]);

    return (
        <Layout title="Apply">
            <StateMachineProvider>
                <ApplyForm />
            </StateMachineProvider>
        </Layout>
    );
};

ApplyPage.propTypes = {
    location: PropTypes.shape({
        purchase: PropTypes.string
    })
};

export default ApplyPage;
