import styled from 'styled-components';

const FormFieldText = styled.textarea`
    border: 1px solid #000;
    background-color: #000;
    color: #757575;
    min-height: 240px;
    font-size: 14px;
    padding: 14px;
    width: 100%;
    resize: vertical;
`;

export default FormFieldText;
