const update = (state, formData) => ({
    ...state,
    formData: {
        ...state.formData,
        ...formData,
        gdpr: formData.gdpr ? 'true' : 'false'
    }
});

export default update;
