import styled from 'styled-components';

const FormLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    margin-top: 20px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
`;

export default FormLabel;
