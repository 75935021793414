import styled, { css } from 'styled-components';

const disabledMixin = css`
    background-color: #393834;
    color: grey;
    pointer-events: none;

    :hover,
    :active {
        background-color: #393834;
        color: grey;
    }
`;

const FormButton = styled.div`
    display: inline-block;
    color: white;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    background-color: #393834;
    font-size: 14px;
    margin-top: 20px;
    padding: 14px 14px;
    text-align: center;

    :hover {
        background-color: #e31d1a;
    }

    :active {
        background-color: #bd1917;
    }

    ${({ disabled }) => disabled && disabledMixin}
`;

export default FormButton;
