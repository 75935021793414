import Axios from 'axios';

const API_KEY = 'zQoNLLBuMnuf9A7tDIqG5sds0igl4d06NfLXNp7c';
const API_URL = 'https://mbqm0vzyn8.execute-api.eu-west-1.amazonaws.com/API';

const axios = Axios.create({
    baseURL: API_URL
});

axios.defaults.headers.common['x-api-key'] = API_KEY;

async function submit(data) {
    const { data: response } = await axios.post('client', data);

    if (!response.client) {
        throw Error('Failure to persist form data');
    }
}

export { submit };
