import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import checkMark from '../../assets/images/check.svg';

const FormFieldRadioGroup = styled.label`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px 14px 14px 18px;
    background-color: #000;
    margin-bottom: 10px;

    ::before,
    ::after {
        content: ' ';
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }

    ::after {
        clear: both;
    }
`;

const checkMarkMixin = css`
    background-color: #f52a2a;
    background-image: url(${checkMark});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
`;

const RadioButton = styled.div`
    width: 32px;
    height: 32px;
    margin-right: 20px;
    margin-left: 0px;
    margin-top: 0px;
    border-width: 0px;
    border-radius: 8%;
    background-color: #757575;
    line-height: normal;
    float: left;

    ${({ selected }) => selected && checkMarkMixin}
`;

const RadioInput = styled.input`
    opacity: 0;
    position: absolute;
    z-index: -1;
`;

const labelMixin = css`
    display: inline-block;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0px;
`;

const RadioText = styled.span`
    font-size: ${({ small }) => (small ? 11 : 15)}px;
    text-align: left;
    line-height: 1.2em;
    color: ${({ small }) => (small ? '#757575' : '#ffffff')};

    ${({ radioLabel }) => radioLabel && labelMixin}
`;

const FormFieldRadio = React.forwardRef(
    ({ selected, value, title, description, ...props }, ref) => (
        <FormFieldRadioGroup>
            <RadioButton selected={selected} />
            <RadioInput type="radio" value={value} ref={ref} {...props} />
            <RadioText radioLabel>
                {title}
                <br />
                <RadioText small>{description}</RadioText>
            </RadioText>
        </FormFieldRadioGroup>
    )
);

FormFieldRadio.propTypes = {
    selected: PropTypes.bool,
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string
};

FormFieldRadio.defaultProps = {
    selected: false,
    subtitle: null
};

export default FormFieldRadio;
