import React from 'react';
import PropTypes from 'prop-types';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './update-action';
import validation from './validation';
import {
    Title,
    SubTitle,
    Form,
    FormGroup,
    FormLabel,
    FormFieldInput,
    FormButton,
    FormError
} from '../../components/form';

const Step1UserDetails = ({ onNext }) => {
    const { register, handleSubmit, errors } = useForm();
    const { action, state } = useStateMachine(updateAction);

    const onSubmit = data => {
        action(data);
        onNext();
    };

    return (
        <>
            <Title>Apply Now</Title>
            <SubTitle>First we need some basic information about yourself.</SubTitle>
            <Form>
                <FormGroup>
                    <FormLabel>Your Name</FormLabel>
                    <FormFieldInput
                        name="name"
                        type="text"
                        autocomplete="name"
                        placeholder="First and Last name"
                        defaultValue={state.formData.name}
                        ref={register(validation.name)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Your Email Address</FormLabel>
                    <FormFieldInput
                        name="email"
                        type="email"
                        autocomplete="name"
                        placeholder="Either personal or corporate"
                        defaultValue={state.formData.email}
                        ref={register(validation.email)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Phone number</FormLabel>
                    <FormFieldInput
                        name="phone"
                        type="tel"
                        autocomplete="tel-country-code"
                        placeholder="Ex: +1 123 123456"
                        defaultValue={state.formData.phone}
                        ref={register(validation.phone)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormError>
                        {errors.name && <ErrorMessage errors={errors} name="name" as="p" />}
                        {errors.email && <ErrorMessage errors={errors} name="email" as="p" />}
                        {errors.phone && <ErrorMessage errors={errors} name="phone" as="p" />}
                    </FormError>
                </FormGroup>
                <FormGroup>
                    <FormButton onClick={handleSubmit(onSubmit)}>&lt;Next&gt;</FormButton>
                </FormGroup>
            </Form>
        </>
    );
};

Step1UserDetails.propTypes = {
    onNext: PropTypes.func
};

Step1UserDetails.defaultProps = {
    onNext: () => {}
};

export default Step1UserDetails;
