import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

const StatusLine = styled.div`
    width: 100%;
    height: 6px;
    max-width: 15%;
    margin-right: 10px;
    margin-left: 10px;
    background-color: ${({ done }) => (done ? '#e2e2e2' : '#393834')};
    cursor: ${({ done }) => (done ? 'pointer' : 'auto')};
`;

const FormStatus = ({ maxStep, step, onClick }) => (
    <Container>
        {[...Array(maxStep).keys()]
            .map(index => index + 1)
            .map(index => (
                <StatusLine key={index} done={step >= index} onClick={() => onClick(index)} />
            ))}
    </Container>
);

export default FormStatus;
