import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Step1UserDetails from './step1-user-details';
import Step2UserKnowledge from './step2-user-knowledge';
// import Step3Referral from './step3-referral';
import Step4Questions from './step4-questions.jsx';
import Step5Checkout from './step5-checkout.jsx';
import { FormStatus } from '../../components/form';

import acLogo from '../../assets/images/logo_codeforall.svg';

const Logo = styled.img`
    max-width: 240px;
    margin: 80px 0;
    @media (min-width: 0) and (max-width: 768px) {
        margin: 40px 0;
        max-width: 180px;
    }
`;

const Section = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 80px;
    background-color: #2a2926;
    background: linear-gradient(90deg, #131313 57px, transparent 1%) 50%,
        linear-gradient(#131313 57px, transparent 1%) 50%, #333;
    background-size: 60px 60px;

    @media (min-width: 0) and (max-width: 768px) {
        padding-top: 40px;
        background: #242424;
    }

    @media (min-width: 0) and (max-width: 640px) {
        padding-top: 0px;
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 560px;
    min-height: 100px;
    padding: 32px 60px 60px;
    background-color: #242424;
    box-shadow: 28px 28px 0px 0px #e31d1a;

    @media (min-width: 0) and (max-width: 768px) {
        max-width: 80%;
        padding: 32px 40px 40px;
        box-shadow: none;
    }

    @media (min-width: 0) and (max-width: 640px) {
        max-width: 100%;
        padding: 32px 30px 30px;
    }
`;

const stepComponentMap = {
    1: Step1UserDetails,
    2: Step2UserKnowledge,
    // 3: Step3Referral,
    3: Step4Questions,
    4: Step5Checkout
};

const nextStep = step => (step < 4 ? step + 1 : step);

const ApplyForm = () => {
    const [step, setStep] = useState(1);
    const Step = stepComponentMap[step];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const goBack = clickedStep => {
        if (clickedStep < step) {
            setStep(clickedStep);
        }
    };

    return (
        <Section>
            <Container>
                <Step onNext={() => setStep(nextStep(step))} />
                {step < 4 && <FormStatus maxStep={3} step={step} onClick={goBack} />}
            </Container>
            <Logo src={acLogo} alt="logo" />
        </Section>
    );
};

export default ApplyForm;
