import styled from 'styled-components';

const FormFieldInput = styled.input`
    border: 1px solid #000000;
    background-color: #000000;
    color: #757575;
    font-size: 14px;
    padding: 24px 14px;
    display: block;
    width: 100%;
    height: 38px;
    margin-bottom: 10px;
    line-height: 1.428571429;
    vertical-align: middle;
`;

export default FormFieldInput;
