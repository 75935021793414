import React from 'react';
import styled, { css } from 'styled-components';

const warningMixin = css`
    background-color: black;
    color: #f52a2a;
`;

const errorMixin = css`
    background-color: #f52a2a;
    color: white;
`;

const hasChild = children => React.Children.toArray(children).length;

const FormError = styled.div`
    padding: ${({ children }) => (hasChild(children) ? '10' : '0')}px;
    font-size: 12px;
    line-height: 1.4em;
    margin-top: 20px;
    margin-bottom: 20px;

    ${({ error }) => (error ? errorMixin : warningMixin)};

    span {
        display: block;
    }

    p {
        margin: 0;
    }
`;

export default FormError;
