import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './update-action';
import validation from './validation';

import {
    Title,
    SubTitle,
    Form,
    FormGroup,
    FormLabel,
    FormFieldSelect,
    FormButton,
    FormError
} from '../../components/form';

const Step2UserKnowledge = ({ onNext }) => {
    const { register, watch, setValue, handleSubmit, errors } = useForm();
    const { action, state } = useStateMachine(updateAction);

    const onSubmit = data => {
        action(data);
        onNext();
    };

    const watchPurchase = watch('purchase', state.formData.purchase);

    useEffect(() => {
        setValue('purchase', watchPurchase);
    }, []);

    return (
        <>
            <Title>Code Knowledge</Title>
            <SubTitle>Tell us a bit more about your coding skills...</SubTitle>
            <Form>
                <FormGroup>
                    <FormLabel>What do you know about code?</FormLabel>
                    <FormFieldSelect
                        name="skills"
                        defaultValue={state.formData.skills}
                        ref={register(validation.skills)}
                    >
                        <option value="">Select one of the options</option>
                        <option value="noob">Know nothing about code</option>
                        <option value="junior">Junior Developer</option>
                        <option value="intermediate">Intermediate Developer</option>
                        <option value="expert">Expert Developer</option>
                    </FormFieldSelect>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Are you an &lt;Academia de Código_&gt; alumni?</FormLabel>
                    <FormFieldSelect
                        name="ac"
                        defaultValue={state.formData.ac}
                        ref={register(validation.ac)}
                    >
                        <option value="">Select one of the options</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </FormFieldSelect>
                </FormGroup>
                <FormGroup>
                    <FormError>
                        {errors.skills && <ErrorMessage errors={errors} name="skills" as="p" />}
                        {errors.ac && <ErrorMessage errors={errors} name="ac" as="p" />}
                        {errors.purchase && <ErrorMessage errors={errors} name="purchase" as="p" />}
                    </FormError>
                </FormGroup>
                <FormGroup>
                    <FormButton onClick={handleSubmit(onSubmit)}>&lt;Next&gt;</FormButton>
                </FormGroup>
            </Form>
        </>
    );
};

Step2UserKnowledge.propTypes = {
    onNext: PropTypes.func
};

Step2UserKnowledge.defaultProps = {
    onNext: () => {}
};

export default Step2UserKnowledge;
