import styled from 'styled-components';

const FormFieldSelect = styled.select`
    appearance:none;
    display: block;
    width: 100%;
    height: 38px;
    margin-bottom: 10px;
    line-height: 1.428571429;
    vertical-align: middle;
    border: 1px solid #cccccc;
    border-style: solid;
    border-width: 1px;
    border-color: #000;
    border-radius: 0px;
    background-color: #000;
    font-family: 'SpaceMono', sans-serif;
    color: #757575;
    font-size: 14px;
    text-indent: 5px;
    padding: 0 14px;
    height: 50px;

    &:focus {
        border-color: #000;
    }
`;

export default FormFieldSelect;
