import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckBoxLabel = styled.label`
    display: flex;
    background-color: black;
    color: white;
    padding: 10px;
    font-size: 12px;
    line-height: 1.4em;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const CheckBoxText = styled.span`
    display: inline-block;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0px;
    padding-left: 10px;
`;

const CheckBoxInput = styled.input`
    margin-top: 3px;

    @media (min-width: 0) and (max-width: 768px) {
        margin: 10px;
        transform: scale(2);
    }
`;

const FormCheck = React.forwardRef(({ children, ...props }, ref) => (
    <CheckBoxLabel>
        <CheckBoxInput type="checkbox" ref={ref} {...props} />
        <CheckBoxText>{children}</CheckBoxText>
    </CheckBoxLabel>
));

FormCheck.propTypes = {
    children: PropTypes.node.isRequired
};

export default FormCheck;
